<template>
  <div class="app-eva">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>试卷评价</el-breadcrumb-item>
        <el-breadcrumb-item>评价列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="eva-content">
      <div class="left">
        <div class="left-top">
          <span class="nowrap">考试列表</span>
        </div>
        <div class="exam">
          <div
            :class="[idIndex === exam.examId ? 'focusS' : '', treeClick ? 'click-forbid' : '']"
            class="list"
            @click="
              idIndex = exam.examId
              getExamEvaById(idIndex)
            "
            v-for="(exam, eIndex) in examEvaList"
            :key="`exam-${eIndex}`"
          >
            {{ exam.examName }}
          </div>
        </div>
        <div class="page">
          <el-pagination
            background
            small
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total,prev, pager, next"
            :total="listQuery.total"
          />
        </div>
      </div>
      <div id="examEvaData" class="right">
        <div class="nData" v-if="evaList.length === 0">暂无数据</div>
        <div class="data" v-else>
          <div class="listS" v-for="(eva, eIndex) in evaList" :key="`eva-${eIndex}`">
            <div style="width: 100%;justify-content: space-between;margin-top: 5px;margin-left: 5px;">
              <div>
                <el-avatar class="avatar" size="medium" :src="eva.avatar || image" />
                <div style="padding-left: 8px;flex-direction: column; margin-left: 3px">
                  <span style="font-weight: bold">{{ eva.creatorName }}</span>
                  <span style="color: #7e7f8091;margin-top: 5px">{{ eva.createTime | getTime }}</span>
                </div>
              </div>
              <div>
                <el-button style="padding-top: 2px; padding-right: 10px" type="text" @click="delTooltip(eva)">删除</el-button>
              </div>
            </div>
            <div class="evaContent">
              <!--                <span style="white-space: nowrap">评论:</span><el-divider direction="vertical" />-->
              <span>{{ eva.content || '暂无评价' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from '../../../../util/util'
import exam from '../../../../api/exam'
import image from '../../../../assets/defaultAvatar.svg'
export default {
  name: 'examEva',
  data() {
    return {
      examNameSear: '',
      examEvaList: [],
      evaList: [],
      row: null,
      idIndex: 0,
      listQuery: {
        examName: '',
        pageSize: 10,
        pageNum: 0,
        total: 0
      },
      IsLoading: null,
      mulSeleList: [],
      treeClick: false
    }
  },
  filters: {
    getTime(date) {
      let d = new Date(date)
      return util.dateTime(d)
    }
  },
  created() {
    this.getList()
  },
  computed: {
    image: function() {
      return image
    }
  },
  methods: {
    areaLoad(elementName) {
      this.IsLoading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        target: document.querySelector(`#${elementName}`)
      })
    },
    getList: function() {
      exam.getEvaluationList(this.listQuery).then(res => {
        this.examEvaList = res.res.data
        this.listQuery.total = res.res.total
        // console.log(this.examEvaList)
        if (this.examEvaList.length > 0) {
          let row = this.examEvaList.findIndex(i => i.examId === this.idIndex)
          if (row === -1) {
            this.idIndex = this.examEvaList[0].examId
          }
          this.getExamEvaById(this.idIndex)
        } else {
          this.evaList = []
        }
      })
    },
    getExamEvaById(id) {
      if (this.treeClick) {
        return
      }
      this.treeClick = true
      this.areaLoad('examEvaData')
      exam
        .getEvaByExamId(id)
        .then(res => {
          this.evaList = res.res
          this.IsLoading.close()
          setTimeout(() => {
            this.treeClick = false
          }, 1000)
        })
        .catch(() => {
          this.IsLoading.close()
        })
    },
    handleSizeChange(size) {
      this.listQuery.pageNum = 0
      this.listQuery.pageSize = size
      this.getList()
    },
    handleCurrentChange(num) {
      this.listQuery.pageNum = num - 1
      this.getList()
    },
    handleSelect(data) {
      this.mulSeleList = data
    },
    delTooltip(index) {
      this.$confirm('确定删除选中的评论吗?', '提示', {
        confirmButtonText: '',
        cancelButtonText: '',
        type: 'warning'
      }).then(() => {
        this.del(index)
      })
    },
    del(row) {
      let form = {
        evaluationId: []
      }
      form.evaluationId.push(row.id)
      exam.examEvaDel(form).then(res => {
        if (res.res) {
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.getList()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/evaluation';
.container-title {
  width: 100%;
  height: 49.53px;
  background: rgba(228, 233, 239, 1);
  padding: 16px 37px 16px 10px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: rgba(58, 78, 100, 1);
  display: flex;
  align-items: center;
}
.click-forbid {
  pointer-events: none;
  cursor: no-drop !important;
}
</style>
